import React, { useEffect } from 'react'
//import Form from "./form"

const ContactOverlay = () => {
	useEffect(() => {
		window.hbspt.forms.create({
			region: 'na1',
			portalId: '9226978',
			formId: '22b7674b-fff1-4000-9826-ce20089236c8',
			target: '#contact-form',
		})
	}, [])

	return (
		<div className='contact--overlay'>
			<div className='pos-rel'>
				<div className='p40 inner flex'>
					<div className='mla contact-form-wrapper mta mba w-100 max-550'>
						<div id='contact-form' />
						<div className='mt80 flex'></div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ContactOverlay
