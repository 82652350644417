import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import * as React from 'react'

const Header = ({ noButton}) => {
	const onClick = () => {
		const overlay = document.querySelector('.contact--overlay')
		if (overlay.classList.contains('active')) {
			overlay.classList.remove('active')
			document.querySelector('.register-button').innerHTML = 'Register <span className="m-hide">Your Interest</span>'
		} else {
			overlay.classList.add('active')
			document.querySelector('.register-button').innerHTML = 'Close X'
		}
	}

	return (
		<header className='masthead'>
			<div className='flex p40 align-center'>
				<Link to='/' className='logo--main'>
				</Link>
			{!noButton &&	<button className='mla m0 uppercase small h4 button register-button' onClick={onClick}>
					<span>Register </span> <span className='m-hide'> Your Interest</span>
				</button>}
			</div>
		</header>
	)
}

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
