/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import '../scss/site.scss'
import ContactOverlay from './contactOverlay'
import Header from './header'

const Layout = ({ children, noButton = false }) => {
	return (
		<>
			<main>
				<Header noButton={noButton} />
				<ContactOverlay />
				{children}
			</main>
		</>
	)
}

export default Layout
